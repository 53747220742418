//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MainHeader from "../headers/MainHeader"
import Sidebar from "../components/Sidebar"
import {mapGetters, mapMutations, mapState} from 'vuex'
import router from "@/router";

export default {
  name: "TheProfile",
  metaInfo () {
    return {
      title: this.title,
    }
  },
  created() {
    if (this.showDocumentsFirst !== null && this.showDocumentsFirst) {
      router.history.push({
        name: 'documents'
      })
    }
  },
  watch: {
    showDocumentsFirst(value) {
      if (value) {
        router.history.push({
          name: 'documents'
        })
      }
    },
    $route(to) {
      this.isPersonalCertificatePage = to.name === "certificate"
    }
  },
  data() {
    return {
      title: this.$i18n.t('profile.title'),
      isPersonalCertificatePage: false
    }
  },
  methods: {
    hideModals() {
      this.hideProfileModal()

      this.hideUserModal()
    },

    ...mapMutations([
      'hideProfileModal',
      'hideUserModal'
    ])
  },
  computed: {
    layoutKey() {
      return (this.currentStudent && this.currentStudent.ID)
          ? this.$route.fullPath + '_' + this.currentStudent.ID
          : this.$route.fullPath
    },
    ...mapState([
        'token',
        'currentStudent',
        'authMethod',
        'showDocumentsFirst',
    ]),
    ...mapGetters([
      'isIdAuthMethod',
      'isPhoneAuthMethod',
      'isContent',
    ])
  },
  components: {Sidebar, MainHeader},
}
